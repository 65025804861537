import Vue from 'vue';
import StudioPageBase from '@/support/studioPageBase';
import { Component, Watch } from 'vue-property-decorator';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import PlayerUtil from '@/support/playerUtil';
import Util from '@/support/utility';
import Browser from '@/support/browser';
import { ServerDataModule } from '@/store/serverData';

@Component
export default class ConfirmEmailErrorComponent extends StudioPageBase {
    options = {
    };

    get serverData() {
        return ServerDataModule.serverData;
    }

    id: string = null;
    model = Globals.NewRequestAccess;

    loading = true;

    get confirmEmailUrl() {
        let id = this.model.confirmationId.replace(/-/g, "");
        return window.location.origin + "/ConfirmEmail/" + id;
    }

    created() {
        super.created('confirmEmailError', true);

        Debug.setDebugModule('ConfirmEmailError', this);

        this.id = Browser.getRouteParam(this.$route, 'id', null);

        this.model.email = this.serverData.email;
        this.model.fullName = this.serverData.fullName;

        this.getRequestAccessData();
    }

    mounted() {
        super.mounted();

        Debug.log("confirmEmailError mounted");
    }

    protected onLoaded(loaded: boolean) {
        super.onLoaded(loaded);
    }

    async getRequestAccessData() {
        try {
            let response = await PlayerApi.requestAccessConfirmationData(this.id);
            Object.assign(this.model, response);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('confirmEmailError - getRequestAccessData Failed: ' + message);

            PlayerUtil.redirectToError(this.$router);
        } finally {
            this.loading = false;
        }
    }
}
